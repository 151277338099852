import React from "react";
import { Grid, MediaCard, Badge } from "@shopify/polaris";

export default function Integrations() {
  return (
    <>
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
          <MediaCard
            primaryAction={{
              content: "Read More",
              onAction: () => {},
            }}
            description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
          >
            <Badge tone="success">Active</Badge>
            <img
              src="/whatsapp.png"
              alt="Flook.ai Whatsapp"
              height={200}
            />
          </MediaCard>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
        <MediaCard
            primaryAction={{
              content: "Read More",
              onAction: () => {},
            }}
            description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
          >
            <Badge tone="success">Active</Badge>
            <img
              src="/instagram.png"
              alt="Flook.ai Whatsapp"
              height={200}
            />
          </MediaCard>
        </Grid.Cell>
      </Grid>
    </>
  );
}
