import React, { useState, useCallback } from "react";
import { Select, Grid, Card, Icon } from "@shopify/polaris";
import { StarOutlineMinor, ChannelsMajor, ConnectMinor, OrdersMajor } from "@shopify/polaris-icons";
import styles from "../../styles/layouts/Dashboard/dashboard.module.css";
import { LineChart, PolarisVizProvider } from "@shopify/polaris-viz";

const chartData = [
  {
    data: [
      {
        key: "January",
        value: 5663,
      },
      {
        key: "February",
        value: 7349,
      },
      {
        key: "March",
        value: 9795,
      },
      {
        key: "April",
        value: 7396,
      },
      {
        key: "May",
        value: 14000,
      },
      {
        key: "June",
        value: 12484,
      },
      {
        key: "July",
        value: 4878,
      },
    ],
    name: "Views",
    color: "lightseagreen",
  },
  {
    data: [
      {
        key: "January",
        value: 4237,
      },
      {
        key: "February",
        value: 5024,
      },
      {
        key: "March",
        value: 5730,
      },
      {
        key: "April",
        value: 5587,
      },
      {
        key: "May",
        value: 5303,
      },
      {
        key: "June",
        value: 5634,
      },
      {
        key: "July",
        value: 3238,
      },
    ],
    name: "Sales",
    color: "lightgreen",
  },
];

export default function Dashboard() {
  const [selected, setSelected] = useState("today");

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const options = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];

  return (
    <>
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <Card sectioned>
            <h3>Reviews</h3>

            <div className={styles["card-data-container"]}>
              <div>
                <h1>
                  <Icon source={StarOutlineMinor} />
                </h1>
              </div>

              <div>
                <h1>961</h1>
              </div>
            </div>
          </Card>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <Card sectioned>
            <h3>Sales from Affiliates</h3>

            <div className={styles["card-data-container"]}>
              <div>
                <h1>
                  <Icon source={ChannelsMajor} />
                </h1>
              </div>

              <div>
                <h1>758</h1>
              </div>
            </div>
          </Card>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <Card sectioned>
            <h3>Reward Points Used</h3>

            <div className={styles["card-data-container"]}>
              <div>
                <h1>
                  <Icon source={ConnectMinor} />
                </h1>
              </div>

              <div>
                <h1>530145</h1>
              </div>
            </div>
          </Card>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <Card sectioned>
            <h3>Orders from Affiliates</h3>

            <div className={styles["card-data-container"]}>
              <div>
                <h1>
                  <Icon source={OrdersMajor} />
                </h1>
              </div>

              <div>
                <h1>53Mn</h1>
              </div>
            </div>
          </Card>
        </Grid.Cell>
      </Grid>

      <br />
      <div className={styles["filters-container"]}>
        <Select
          options={options}
          onChange={handleSelectChange}
          value={selected}
        />
      </div>
      <br />
      <Card padding="1000">
        <PolarisVizProvider
          themes={{
            Light: {
              legend: {
                backgroundColor: "white",
              },
            },
          }}
        >
          <div
            style={{
              height: 500,
            }}
          >
            <LineChart data={chartData} theme="Light" />
          </div>
        </PolarisVizProvider>
      </Card>
    </>
  );
}
