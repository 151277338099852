import React, { useCallback, useState } from "react";
import { Card, Tabs } from "@shopify/polaris";
import BasicSettings from "../../components/Settings/StoreSettings/Basic";
import SecuritySettings from "../../components/Settings/StoreSettings/Security";
import IntegrationsSettings from "../../components/Settings/StoreSettings/Integrations";

export default function Settings() {
  // Tabs configuration

  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "profile-settings",
      content: "Profile",
      panelID: "profile-settings",
    },
    {
      id: "auth-settings",
      content: "Security / Authentication",
      panelID: "auth-settings",
    },
    {
      id: "integrations-settings",
      content: "Integrations",
      panelID: "integrations-settings",
    },
  ];

  // //////////////////

  return (
    <div>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <br />
        <Card>
          {selected === 0 && <BasicSettings />}
          {selected === 1 && <SecuritySettings />}
          {selected === 2 && <IntegrationsSettings />}
        </Card>
      </Tabs>
      <br />
      <br />
    </div>
  );
}
