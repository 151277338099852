import React, { useCallback, useState } from "react";
import {
  Icon,
  Card,
  DataTable,
  TextField,
  ChoiceList,
  RangeSlider,
  Filters,
} from "@shopify/polaris";
import { PlusMinor } from "@shopify/polaris-icons";
import styles from "../../styles/layouts/Wallets/Wallets.module.css";

const rows = [
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
  ["124234", "Jane", "2675", "45", "$20.00"],
];

export default function Wallets() {
  // Filters Attribute

  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState(undefined);
  const [queryValue, setQueryValue] = useState(undefined);

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(
    () => setTaggedWith(undefined),
    []
  );
  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (!isEmpty(moneySpent)) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  // /////////////////

  return (
    <>
      <div className={styles["header"]}>
        <button>
          Create Campaign{" "}
          <span>
            <Icon source={PlusMinor} />
          </span>
        </button>
      </div>

      <Card>
        <Filters
          queryValue={queryValue}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
        />
        {/* /////// */}

        <DataTable
          columnContentTypes={["text", "text", "text", "numeric", "numeric"]}
          headings={[
            "Customer Id",
            "Customer Name",
            "Balance Points",
            "Orders",
            "Total Spent",
          ]}
          rows={rows}
          // footerContent={`Showing ${rows.length} of ${rows.length} results`}
          pagination={{
            hasNext: true,
            hasPrevious: true,
            label: "Page 1 / 498 - 48599 (Total Reviews)",
            onNext: () => {},
            onPrevious: () => {},
          }}
        />
      </Card>
    </>
  );
}

function disambiguateLabel(key, value) {
  switch (key) {
    case "moneySpent":
      return `Money spent is between $${value[0]} and $${value[1]}`;
    case "taggedWith":
      return `Tagged with ${value}`;
    case "accountStatus":
      return value?.map((val) => `Customer ${val}`).join(", ");
    default:
      return value;
  }
}

function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}
