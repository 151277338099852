import React, { useState, useCallback } from "react";
import { Card, DataTable, TextField, Icon } from "@shopify/polaris";
import { SearchMajor } from "@shopify/polaris-icons";
import TopBar from "../../components/Reviews/TopBar";
import FeaturedReviews from "../../components/Reviews/FeaturedReviews";

export default function Reviews() {
  const [textFieldValue, setTextFieldValue] = useState("");

  const rows = [
    ["124234", "Jane", "Cooper", "2020-09-30", "$20.00"],
    ["124234", "Jane", "Cooper", "2020-09-30", "$20.00"],
    ["124234", "Jane", "Cooper", "2020-09-30", "$20.00"],
    ["124234", "Jane", "Cooper", "2020-09-30", "$20.00"],
    ["124234", "Jane", "Cooper", "2020-09-30", "$20.00"],
    ["124234", "Jane", "Cooper", "2020-09-30", "$20.00"],
    ["124234", "Jane", "Cooper", "2020-09-30", "$20.00"],
  ];

  const handleTextFieldChange = useCallback(
    (value) => setTextFieldValue(value),
    []
  );

  return (
    <>
      <TopBar />
      <br />
      <FeaturedReviews />
      <br />
      <br />
      <br />
      <Card>
        <TextField
          value={textFieldValue}
          onChange={handleTextFieldChange}
          suffix={<Icon source={SearchMajor} />}
          autoComplete="off"
          placeholder="Search Reviews"
        />
        <DataTable
          columnContentTypes={["text", "text", "text", "numeric", "numeric"]}
          headings={[
            "Customer Id",
            "Customer Name",
            "Order Date",
            "Order Amount",
            "Total Spending",
          ]}
          rows={rows}
          // footerContent={`Showing ${rows.length} of ${rows.length} results`}
          pagination={{
            hasNext: true,
            hasPrevious: true,
            label: "Page 1 / 498 - 48599 (Total Reviews)",
            onNext: () => {},
            onPrevious: () => {},
          }}
        />

        {/* <Pagination
          hasPrevious
          onPrevious={() => {
            console.log("Previous");
          }}
          hasNext
          onNext={() => {
            console.log("Next");
          }}
        /> */}
      </Card>
    </>
  );
}
