import React, { useState, useCallback } from "react";
import { Card, Button, Popover, OptionList, Box } from "@shopify/polaris";
import styles from "../../styles/components/Reviews/TopBar.module.css";

const SELECT_RANGE_OPTION = [
  { label: "Last 30 Days", value: "last_30_days" },
  { label: "Last 60 Days", value: "last_60_days" },
  { label: "Last 90 Days", value: "last_90_days" },
];

const RANGE_OPTIONS_TITLE_MAPPER = {
  last_30_days: "Last 30 Days",
  last_60_days: "Last 60 Days",
  last_90_days: "Last 90 Days",
};

export default function TopBar() {
  const [selected, setSelected] = useState(["last_30_days"]);
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const activator = (
    <Button
      onClick={togglePopoverActive}
      className={styles["select-range-btn"]}
      disclosure
    >
      {RANGE_OPTIONS_TITLE_MAPPER[selected[0]]}
    </Button>
  );

  return (
    <div className={styles["top-bar-container"]}>
      <div>
        <Popover
          active={popoverActive}
          activator={activator}
          onClose={togglePopoverActive}
        >
          <OptionList
            onChange={setSelected}
            options={SELECT_RANGE_OPTION}
            selected={selected}
          />
        </Popover>
      </div>
      <div>
        Reviews
        <p>587</p>
      </div>
      <div>
        Invite Sent
        <p>1478</p>
      </div>

      <div>
        Reviews With Visuals
        <p>254</p>
      </div>
    </div>
  );
}
