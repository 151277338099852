import { AppProvider } from "@shopify/polaris";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import "@shopify/polaris/build/esm/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { BrowserRouter, Link as ReactRouterLink } from "react-router-dom";
import ToastContextProvider from "./global-contexts/Toast";
import StoreDataContextProvider from "./global-contexts/StoreData";
import "@shopify/polaris-viz/build/esm/styles.css";
import Router from "./layouts/layout-provider";
import "./styles/App.css";

function App() {
  return (
    <BrowserRouter>
      <AppProvider theme="light" i18n={enTranslations} linkComponent={Link}>
        <PolarisVizProvider>
          <ToastContextProvider>
            <StoreDataContextProvider>
              <Router />
            </StoreDataContextProvider>
          </ToastContextProvider>
        </PolarisVizProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

function Link({ children, url = "", external, ref, ...rest }) {
  // react-router only supports links to pages it can handle itself. It does not
  // support arbirary links, so anything that is not a path-based link should
  // use a reglar old `a` tag
  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

export default App;
