import React, { createContext, useState } from "react";
import { Frame, Toast } from "@shopify/polaris";

export const ToastContext = createContext({});

export default function ToastContextComponent({ children }) {
  const [toast, setToast] = useState({
    message: "",
    error: false,
    open: false,
  });

  function handleDismiss() {
    setToast({ message: "", error: false, open: false });
  }

  return (
    <ToastContext.Provider value={{ toast, setToast }}>
      <Frame>
        {toast.open && (
          <Toast
            error={toast.error}
            content={toast.message}
            onDismiss={handleDismiss}
          />
        )}
        {children}
      </Frame>
    </ToastContext.Provider>
  );
}
