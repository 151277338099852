import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Checkbox,
  Text,
  Box,
  Grid,
  EmptyState,
  Button,
  Icon,
  Popover,
  ActionList,
  FormLayout,
  TextField,
} from "@shopify/polaris";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import styles from "../../../styles/layouts/Settings/ReviewSettings.module.css";

export default function AffiliateSetting() {
  const [emailChecked, setEmailChecked] = useState(true);
  const [smsChecked, setSMSChecked] = useState(true);
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const handleEmailChange = useCallback(
    (newChecked) => setEmailChecked(newChecked),
    []
  );
  const handleSMSChange = useCallback(
    (newChecked) => setSMSChecked(newChecked),
    []
  );
  const handleWhatsappChange = useCallback(
    (newChecked) => setWhatsappChecked(newChecked),
    []
  );

  useEffect(() => {
    console.log("AffiliateSetting");
  }, []);

  return (
    <>
      <Card roundedAbove="sm">
        <Text as="h2" variant="headingSm">
          Review invites
        </Text>
        <Text as="p" variant="bodySm">
          Enabled methods will be checked.
        </Text>

        <Box paddingBlockStart="200">
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <Checkbox
                label="Email"
                checked={emailChecked}
                onChange={handleEmailChange}
              />
            </Grid.Cell>

            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <Checkbox
                label="SMS"
                checked={smsChecked}
                onChange={handleSMSChange}
              />
            </Grid.Cell>

            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <Checkbox
                label="WhatsApp"
                checked={whatsappChecked}
                onChange={handleWhatsappChange}
              />
            </Grid.Cell>
          </Grid>
        </Box>
      </Card>

      <br />

      <Card roundedAbove="sm">
        <Text as="h2" variant="headingSm">
          Email Templates
        </Text>
        <br />
        <Box paddingBlockStart="200">
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <div className={styles["review-email-template-card"]}>
                <div className={styles["review-email-template-card-header"]}>
                  <div
                    className={
                      styles["review-email-template-card-header-button"]
                    }
                  >
                    <Button variant="primary" tone="success">
                      Activated
                    </Button>
                  </div>
                  <div
                    className={styles["review-email-template-card-header-icon"]}
                    onClick={togglePopoverActive}
                  >
                    <Popover
                      active={popoverActive}
                      autofocusTarget="first-node"
                      onClose={togglePopoverActive}
                      activator={<Icon source={MobileVerticalDotsMajor} />}
                    >
                      <ActionList
                        actionRole="menuitem"
                        items={[{ content: "Edit" }, { content: "Activate" }]}
                      />
                    </Popover>
                  </div>
                </div>
                <div className={styles["review-email-template-card-img"]}>
                  <img
                    src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                    height={"100%"}
                    width={"100%"}
                  />
                </div>

                <div
                  className={
                    styles["review-email-template-card-name-container"]
                  }
                >
                  <Text as="p" variant="bodySm">
                    <b>template_001</b>
                  </Text>
                </div>
              </div>
            </Grid.Cell>

            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <div className={styles["review-email-template-card"]}>
                <div className={styles["review-email-template-card-header"]}>
                  <div
                    className={
                      styles["review-email-template-card-header-button"]
                    }
                  >
                    {/* <Button variant="primary" tone="success">
                      Activated
                    </Button> */}
                  </div>
                  <div
                    className={styles["review-email-template-card-header-icon"]}
                    onClick={togglePopoverActive}
                  >
                    <Popover
                      active={popoverActive}
                      autofocusTarget="first-node"
                      onClose={togglePopoverActive}
                      activator={<Icon source={MobileVerticalDotsMajor} />}
                    >
                      <ActionList
                        actionRole="menuitem"
                        items={[{ content: "Edit" }, { content: "Activate" }]}
                      />
                    </Popover>
                  </div>
                </div>
                <div className={styles["review-email-template-card-img"]}>
                  <img
                    src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                    height={"100%"}
                    width={"100%"}
                    onClick={togglePopoverActive}
                  />
                </div>

                <div
                  className={
                    styles["review-email-template-card-name-container"]
                  }
                >
                  <Text as="p" variant="bodySm">
                    <b>template_002</b>
                  </Text>
                </div>
              </div>
            </Grid.Cell>

            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
              <EmptyState
                heading="Add new template"
                action={{ content: "Create New" }}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              ></EmptyState>
            </Grid.Cell>
          </Grid>
        </Box>
      </Card>

      <br />

      <Card>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <Text as="h2" variant="headingSm">
              SMS Templates
            </Text>
            <br />
            <div className={styles["template-list-container"]}>
              <div className={styles["template-name"]}>
                <Text as="p" variant="bodySm">
                  <b>template_001</b>
                </Text>
              </div>
              <div className={styles["template-action-button"]}>
                <Popover
                  active={popoverActive}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  activator={<Icon source={MobileVerticalDotsMajor} />}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[{ content: "Edit" }, { content: "Activate" }]}
                  />
                </Popover>
              </div>
            </div>

            <div className={styles["template-list-container"]}>
              <div className={styles["template-name"]}>
                <Text as="p" variant="bodySm">
                  <b>template_001</b>
                </Text>
              </div>
              <div className={styles["template-action-button"]}>
                <Popover
                  active={popoverActive}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  activator={<Icon source={MobileVerticalDotsMajor} />}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[{ content: "Edit" }, { content: "Activate" }]}
                  />
                </Popover>
              </div>
            </div>

            <div className={styles["template-list-container"]}>
              <div className={styles["template-name"]}>
                <Text as="p" variant="bodySm">
                  <b>template_001</b>
                </Text>
              </div>
              <div className={styles["template-action-button"]}>
                <Popover
                  active={popoverActive}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  activator={<Icon source={MobileVerticalDotsMajor} />}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[{ content: "Edit" }, { content: "Activate" }]}
                  />
                </Popover>
              </div>
            </div>
          </Grid.Cell>

          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
            <Text as="h2" variant="headingSm">
              WhatsApp Templates
            </Text>
            <br />
            <div className={styles["template-list-container"]}>
              <div className={styles["template-name"]}>
                <Text as="p" variant="bodySm">
                  <b>template_001</b>
                </Text>
              </div>
              <div className={styles["template-action-button"]}>
                <Popover
                  active={popoverActive}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  activator={<Icon source={MobileVerticalDotsMajor} />}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[{ content: "Edit" }, { content: "Activate" }]}
                  />
                </Popover>
              </div>
            </div>

            <div className={styles["template-list-container"]}>
              <div className={styles["template-name"]}>
                <Text as="p" variant="bodySm">
                  <b>template_001</b>
                </Text>
              </div>
              <div className={styles["template-action-button"]}>
                <Popover
                  active={popoverActive}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  activator={<Icon source={MobileVerticalDotsMajor} />}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[{ content: "Edit" }, { content: "Activate" }]}
                  />
                </Popover>
              </div>
            </div>

            <div className={styles["template-list-container"]}>
              <div className={styles["template-name"]}>
                <Text as="p" variant="bodySm">
                  <b>template_001</b>
                </Text>
              </div>
              <div className={styles["template-action-button"]}>
                <Popover
                  active={popoverActive}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  activator={<Icon source={MobileVerticalDotsMajor} />}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[{ content: "Edit" }, { content: "Activate" }]}
                  />
                </Popover>
              </div>
            </div>
            <div className={styles["template-list-container"]}>
              <div className={styles["template-name"]}>
                <Text as="p" variant="bodySm">
                  <b>template_001</b>
                </Text>
              </div>
              <div className={styles["template-action-button"]}>
                <Popover
                  active={popoverActive}
                  autofocusTarget="first-node"
                  onClose={togglePopoverActive}
                  activator={<Icon source={MobileVerticalDotsMajor} />}
                >
                  <ActionList
                    actionRole="menuitem"
                    items={[{ content: "Edit" }, { content: "Activate" }]}
                  />
                </Popover>
              </div>
            </div>
          </Grid.Cell>
        </Grid>
      </Card>

      <br />

      <Card>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              type="number"
              label="Send Invite After (in days)"
              onChange={() => {}}
              autoComplete="off"
            />
            <TextField
              type="number"
              label="Reminders"
              onChange={() => {}}
              autoComplete="off"
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              type="number"
              label="Reminders Interval (in days)"
              onChange={() => {}}
              autoComplete="off"
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>

      <br />
      <br />
    </>
  );
}
