import React, { createContext, useState } from "react";

export const StoreDataContext = createContext({});

export default function StoreDataContextComponent({ children }) {
  const [store, setStore] = useState(undefined);

  return (
    <StoreDataContext.Provider value={{ store, setStore }}>
      {children}
    </StoreDataContext.Provider>
  );
}
