import React, { useEffect, useContext, useState } from "react";
import { Button, FormLayout, TextField } from "@shopify/polaris";
import { useSearchParams, useNavigate } from "react-router-dom";
import { LoginFromToken, LoginFromCredentials } from "../../services/login";
import { ToastContext } from "../../global-contexts/Toast";
import { StoreDataContext } from "../../global-contexts/StoreData";
import styles from "../../styles/layouts/Login/Login.module.css";

export default function Login() {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const { store, setStore } = useContext(StoreDataContext);

  const { setToast } = useContext(ToastContext);

  useEffect(() => {
    const token = queryParams.get("verify");

    if (token) {
      handleLogin(token);
    }
  }, []);

  async function handleLogin(token) {
    try {
      const result = await LoginFromToken(token);
      console.log(result);
    } catch (error) {
      setToast({
        message: error.message,
        error: true,
        open: true,
      });
      console.log(error);
    }
  }

  async function handleLoginFromCredentials() {
    try {
      if (!email || !password) {
        setToast({
          message: "Please enter email and password",
          error: true,
          open: true,
        });
        return;
      }

      const result = await LoginFromCredentials(email, password);
      if (
        !result?.data?.result?.data?.token ||
        !result?.data?.result?.data?.store
      ) {
        setToast({
          message: "Please enter valid email and password",
          error: true,
          open: true,
        });
        return;
      }
      localStorage.setItem("token", result.data.result.data.token);
      setStore(result.data.result.data.store);
      navigate("/dashboard");
    } catch (error) {
      setToast({
        message: error.message,
        error: true,
        open: true,
      });
      console.log(error);
    }
  }

  return (
    <>
      <div className={styles["login-page"]}>
        <div className={styles["login-gif-container"]}>
          <img
            src="/Login.gif"
            height="100%"
            width="100%"
            alt="Flook.Ai Login"
          />
        </div>

        <div className={styles["login-form-container"]}>
          <div className={styles["logo-container"]}>
            <img src="/flook-ai-logo.png" alt="logo" height={75} width={75} />
          </div>
          <br />
          <FormLayout.Group>
            <TextField
              type="text"
              label="Email"
              value={email}
              onChange={setEmail}
            />
          </FormLayout.Group>
          <br />
          <FormLayout.Group>
            <TextField
              type="password"
              value={password}
              label="Password"
              onChange={setPassword}
            />
          </FormLayout.Group>

          <br />
          <br />

          <Button variant="primary" onClick={handleLoginFromCredentials}>
            Login
          </Button>
        </div>
      </div>
    </>
  );
}
