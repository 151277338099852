import API_CLIENT from "../../utils/Api";

export async function LoginFromToken(token) {
  return await API_CLIENT.post("/authentication/login/token", {
    token,
  });
}

export async function LoginFromCredentials(email, password) {
  return await API_CLIENT.post("/authentication/login", {
    email,
    password,
  });
}
