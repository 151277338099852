import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import PrivateWrapper from "../components/PrivateWrapper";

import RouterData from "../constants/routes";

export default function Router() {
  const pageRoutes = RouterData.map(
    ({
      path,
      title,
      element: ElementComp,
      protected: isComponentRequiresAuth,
    }) => {
      return (
        <Route
          key={title}
          path={`${path}`}
          element={
            isComponentRequiresAuth ? (
              <PrivateWrapper>{<ElementComp />}</PrivateWrapper>
            ) : (
              <ElementComp />
            )
          }
        />
      );
    }
  );

  RouterData.forEach(
    ({
      path,
      title,
      element: ElementComp,
      protected: isComponentRequiresAuth,
      subNavigationItems,
    }) => {
      if (subNavigationItems) {
        subNavigationItems.forEach(
          ({ path, title, element: SubElementComp }) => {
            pageRoutes.push(
              <Route
                key={title}
                path={`${path}`}
                element={
                  isComponentRequiresAuth ? (
                    <PrivateWrapper>{<SubElementComp />}</PrivateWrapper>
                  ) : (
                    <SubElementComp />
                  )
                }
              />
            );
          }
        );
      }
    }
  );

  pageRoutes.unshift(
    <Route key={"redirect"} path="/" element={<Navigate to="/dashboard" />} />
  );

  return <Routes>{pageRoutes}</Routes>;
}
