import React, { useCallback, useState } from "react";
import { TextField, Button, InlineGrid } from "@shopify/polaris";

export default function Integrations() {
  const [value, setValue] = useState("Jaded Pixel");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  return (
    <>
      <TextField
        label="Login Email"
        value={value}
        name="loginEmail"
        onChange={handleChange}
        autoComplete="off"
      />
      <br />

      <TextField
        label="Login Password"
        value={value}
        type="password"
        name="loginPassword"
        onChange={handleChange}
        autoComplete="off"
      />
      <br />

      <InlineGrid gap={"1000"} columns={2}>

      <Button variant="primary">Update Password</Button>
      <Button variant="primary" tone="critical">Update Email</Button>
      </InlineGrid>

    </>
  );
}
