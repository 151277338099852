import {
  Frame,
  Navigation,
  Page,
  TopBar,
  ContextualSaveBar,
} from "@shopify/polaris";
import React, { useEffect, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StoreDataContext } from "../../global-contexts/StoreData";
import { GetProfile } from "../../services/store";
import routes from "../../constants/routes";

export default function PrivateWrapper({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] =
    React.useState(false);
  const [userMenuActive, setUserMenuActive] = React.useState(false);
  const { store, setStore } = useContext(StoreDataContext);

  const navItems = [];

  routes.forEach((route) => {
    if (route.protected && route.icon) {
      navItems.push({
        label: route.title,
        icon: route.icon,
        badge: route.badge,

        // Route conf
        url: route.path,
        matches: false,

        selected: location.pathname === route.path,
        subNavigationItems: route?.subNavigationItems?.map((subNavItem) => {
          return {
            selected: location.pathname === subNavItem.path,
            label: subNavItem.title,
            icon: subNavItem.icon,
            disabled: subNavItem.disabled,
            badge: subNavItem.badge,

            // Route conf
            matchPaths: [route.path, subNavItem.path],
            url: subNavItem.path,
            matches: true,
          };
        }),
      });
    }
  });

  useEffect(() => {
    // Check Login and redirect to login if not logged in
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      if (!store) {
        GetProfile()
          .then((res) => {
            if (!res?.data?.result?.data?.store) {
              navigate("/login");
            }
            setStore(res?.data?.result?.data?.store);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            navigate("/login");
          });
      } else {
        setLoading(false);
      }
    }
  }, []);

  function handleLogout() {
    setStore(undefined);
    localStorage.removeItem("token");
    navigate("/login");
  }

  const userMenuActions = [
    {
      items: [{ content: "Logout", onAction: handleLogout }],
    },
  ];

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={store?.shopName}
      detail={store?.storeUrl}
      initials={store?.shopName[0]}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  return (
    <>
      {loading ? (
        <div className="loading-container">Loading</div>
      ) : (
        <Frame
          navigation={
            <Navigation
              logoSuffix={
                <div className="nav-header-logo-container">
                  <img
                    src="/flook-ai-logo.png"
                    alt="logo"
                    height={75}
                    width={75}
                  />
                </div>
              }
              location="/"
            >
              <Navigation.Section separator items={navItems} />
            </Navigation>
          }
          topBar={topBarMarkup}
          showMobileNavigation={mobileNavigationActive}
          logo={{
            height: 30,
            width: 30,
            topBarSource: "/flook-ai-logo.png",
            contextualSaveBarSource: "/flook-ai-logo.png",
            accessibilityLabel: "Flook AI Logo",
          }}
        >
          <Page>{children}</Page>

          {/* <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: () => console.log("add form submit logic"),
            loading: false,
            disabled: false,
          }}
          discardAction={{
            onAction: () => console.log("add clear form logic"),
          }}
        /> */}
        </Frame>
      )}
    </>
  );
}
