import React from "react";
import { Grid, MediaCard, VideoThumbnail, Icon } from "@shopify/polaris";
import { ArrowRightMinor } from "@shopify/polaris-icons";
import styles from "../../styles/components/Reviews/FeaturedReviews.module.css";

export default function FeaturedReviews() {
  return (
    <div>
      <div className={styles["header-action-container"]}>
        <p>
          View more &nbsp; <span><Icon source={ArrowRightMinor} /></span>{" "}
        </p>
      </div>
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <MediaCard
            primaryAction={{
              content: "Read More",
              onAction: () => {},
            }}
            description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
          >
            <VideoThumbnail
              videoLength={80}
              thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
              onClick={() => console.log("clicked")}
            />
          </MediaCard>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <MediaCard
            primaryAction={{
              content: "Read More",
              onAction: () => {},
            }}
            description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
          >
            <VideoThumbnail
              videoLength={80}
              thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
              onClick={() => console.log("clicked")}
            />
          </MediaCard>
        </Grid.Cell>

        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <MediaCard
            primaryAction={{
              content: "Read More",
              onAction: () => {},
            }}
            description={`In this course, you’ll learn how the Kular family turned their mom’s recipe book into a global business.`}
          >
            <VideoThumbnail
              videoLength={80}
              thumbnailUrl="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
              onClick={() => console.log("clicked")}
            />
          </MediaCard>
        </Grid.Cell>
      </Grid>
    </div>
  );
}
