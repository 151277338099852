import {
  AnalyticsMajor,
  SettingsMajor,
  StarFilledMinor,
  BalanceFilledMajor,
  ChannelsMajor,
} from "@shopify/polaris-icons";
import Page404 from "../layouts/404";
import Dashboard from "../layouts/Dashboard";
import Login from "../layouts/Login";
import Reviews from "../layouts/Reviews";
import Wallets from "../layouts/Wallets";
import Affiliates from "../layouts/Affiliates";
import Settings from "../layouts/Settings";
import ReviewsSettings from "../layouts/Settings/Reviews";
import AffiliatesSettings from "../layouts/Settings/Affiliates";

const PageRoutes = [
  {
    path: "login",
    element: Login,
    title: "login",
  },
  {
    path: "/dashboard",
    element: Dashboard,
    title: "Dashboard",
    icon: AnalyticsMajor,
    protected: true,
  },
  {
    path: "/reviews",
    element: Reviews,
    title: "Reviews",
    icon: StarFilledMinor,
    protected: true,
  },
  {
    path: "/affiliates",
    element: Affiliates,
    title: "Affiliates",
    icon: ChannelsMajor,
    protected: true,
  },
  {
    path: "/wallets",
    element: Wallets,
    title: "Wallets",
    icon: BalanceFilledMajor,
    protected: true,
  },
  {
    path: "/settings",
    element: Settings,
    title: "Settings",
    icon: SettingsMajor,
    protected: true,
    subNavigationItems: [
      {
        path: "/settings/reviews",
        element: ReviewsSettings,
        title: "Reviews",
        icon: StarFilledMinor,
        disabled: false,
        protected: true,
      },
      {
        path: "/settings/affiliates",
        element: AffiliatesSettings,
        title: "Affiliates / Rewards",
        icon: ChannelsMajor,
        disabled: false,
        protected: true,
      },
    ],
  },
  {
    path: "*",
    element: Page404,
    title: "404",
  },
];

export default PageRoutes;
