import React from "react";
import styles from "../../styles/components/Reviews/TopBar.module.css";

export default function TopBar() {
  return (
    <div className={styles["top-bar-container"]}>
      <div>
        Affiliates Order Count
        <p>345</p>
      </div>
      <div>
        Earning From Affiliate
        <p>587</p>
      </div>
      <div>
        Affiliated Users
        <p>1478</p>
      </div>

      <div>
        Average Per Link Order
        <p>25</p>
      </div>
    </div>
  );
}
