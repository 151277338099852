import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Text,
  Button,
  Popover,
  ActionList,
  FormLayout,
  TextField,
  Select,
  Frame,
  ContextualSaveBar
} from "@shopify/polaris";
import styles from "../../../styles/layouts/Settings/AffiliatesSetting.module.css";

export default function AffiliateSetting() {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState("all");

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleImportedAction = useCallback(
    () => console.log("Imported action"),
    []
  );

  const handleExportedAction = useCallback(
    () => console.log("Exported action"),
    []
  );

  const options = [
    { label: "All", value: "all" },
    { label: "Minimum Orders", value: "min_orders" },
    { label: "Minimum Amount Spent", value: "min_purchase" },
  ];

  const activator = (
    <Button onClick={toggleActive} disclosure>
      ENABLED
    </Button>
  );

  return (
    <>
      <Card>
        <div className={styles["header"]}>
          <Text variant="h5">
            <b>Affiliate & Rewards Program</b>
          </Text>

          <Popover
            active={active}
            activator={activator}
            autofocusTarget="first-node"
            onClose={toggleActive}
          >
            <ActionList
              actionRole="menuitem"
              items={[
                {
                  content: "ENABLED",
                  onAction: handleImportedAction,
                  disabled: true,
                },
                {
                  content: "DISABLED",
                  onAction: handleExportedAction,
                },
              ]}
            />
          </Popover>
        </div>
      </Card>

      <br />

      <Card>
        <Text variant="h3">
          <b>User Settings</b>
          <br />
          <br />
          <FormLayout>
            <FormLayout.Group helpText="User will start getting points and will be able to manage their wallet, once they meet this condition.">
              <Select
                label="Eligible Users Criteria"
                options={options}
                onChange={handleSelectChange}
                value={selected}
              />

              <TextField
                type="number"
                label="Condition Value"
                onChange={() => {}}
                autoComplete="off"
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                type="number"
                label="Points Per Order"
                onChange={() => {}}
                autoComplete="off"
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                type="number"
                label="Points For Affiliate Purchase"
                onChange={() => {}}
                autoComplete="off"
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                type="number"
                label="1 Point Value (In Rs.)"
                onChange={() => {}}
                autoComplete="off"
              />
            </FormLayout.Group>
          </FormLayout>
        </Text>
      </Card>

      <br />

      <Card>
        <Text variant="h3">
          <b>Point Spending Setting</b>
          <br />
          <br />
          <FormLayout>
            <FormLayout.Group>
              <Select
                label="Select Condition"
                options={[
                  {
                    label: "None",
                    value: null,
                  },
                  {
                    label: "Minimum Amount",
                    value: "min_amount",
                  },
                  {
                    label: "Minimum Unique Products",
                    value: "min_items",
                  },
                  {
                    label: "Minimum Quantity",
                    value: "min_quantity",
                  },
                ]}
                onChange={handleSelectChange}
                value={selected}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField
                type="number"
                label="Select Condition Value"
                onChange={() => {}}
                autoComplete="off"
              />
            </FormLayout.Group>

            <FormLayout.Group helpText="User will not be able to use above the specified limit.">
              <Select
                label="Max Spending Per Order"
                options={[
                  {
                    label: "None",
                    value: null,
                  },
                  {
                    label: "Flat",
                    value: "flat",
                  },
                  {
                    label: "Percentage",
                    value: "percentage",
                  },
                ]}
                onChange={handleSelectChange}
                value={selected}
              />
              <TextField
                type="number"
                label="Max Spending Value"
                onChange={() => {}}
                autoComplete="off"
              />
            </FormLayout.Group>

            <FormLayout.Group helpText="User will only able to use point if it matches this minimum requirement">
              <Select
                label="Min Spending Per Order"
                options={[
                  {
                    label: "None",
                    value: null,
                  },
                  {
                    label: "Flat",
                    value: "flat",
                  },
                  {
                    label: "Percentage",
                    value: "percentage",
                  },
                ]}
                onChange={handleSelectChange}
                value={selected}
              />
              <TextField
                type="number"
                label="Min Spending Value"
                onChange={() => {}}
                autoComplete="off"
              />
            </FormLayout.Group>

            <FormLayout.Group></FormLayout.Group>
          </FormLayout>
        </Text>
      </Card>
      <br />
      <br />
    </>
  );
}
