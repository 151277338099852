import React, { useCallback, useState } from "react";
import { Icon, TextField, Grid } from "@shopify/polaris";
import { CircleTickMinor } from "@shopify/polaris-icons";
import styles from "../../../styles/components/Settings/StoreSettings/StoreSettings.module.css";

export default function Basic({ store }) {
  const [value, setValue] = useState("Jaded Pixel");

  const handleChange = useCallback((newValue) => setValue(newValue), []);
  return (
    <>
      <div className={styles["logo-editor-container"]}>
        <img
          src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
          alt="brand name"
        />
        <div>
          <input type="file" />
        </div>
      </div>
      <TextField
        label="Brand Name"
        value={value}
        name="brandName"
        onChange={handleChange}
        autoComplete="off"
      />
      <br />
      <TextField
        label="Legal Name"
        value={value}
        name="legalName"
        onChange={handleChange}
        autoComplete="off"
      />
      <br />

      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <div className={styles["plan"]}>
            <div className={styles["plan-title"]}>
              <h3>Startup ($17 USD)</h3>
              <p>
                This plan is suitable for the brands which has just started.
              </p>
            </div>
            <div className={styles["plan-description"]}>
              <ul>
                <li>Upto 100 orders a month.</li>
                <li>Email Review Invites</li>
                <li>Store's WA Integration / Flook's Default WA Template</li>
                <li>Affiliate Links</li>
                <li>Rewards & Loyalty</li>
                <li>Wallet Management</li>
                <li>Standard Support</li>
              </ul>
            </div>
            <div className={styles["plan-action-btn-container"]}>
              <button>
                Downgrade{" "}
                <span>
                  <Icon source={CircleTickMinor} />
                </span>
              </button>
            </div>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <div className={styles["plan-active"]}>
            <div className={styles["plan-title"]}>
              <h3>SME ($37 USD)</h3>
              <p>This plan is suitable for already doing good in the market.</p>
            </div>
            <div className={styles["plan-description"]}>
              <ul>
                <li>Everything from Startup plan</li>
                <li>400 Orders per month (500 Total)</li>
                <li>Email Review Request custom templates</li>
                <li>Priority Support</li>
              </ul>
            </div>

            <div className={styles["plan-action-btn-container"]}>
              <button>
                Selected{" "}
                <span>
                  <Icon source={CircleTickMinor} />
                </span>
              </button>
            </div>
          </div>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 4, xl: 4 }}>
          <div className={styles["plan"]}>
            <div className={styles["plan-title"]}>
              <h3>Enterprise ($57 USD)</h3>
              <p>This plan is for Enterprises looking for scalability.</p>
            </div>
            <div className={styles["plan-description"]}>
              <ul>
                <li>Everything from SME Plan</li>
                <li>1500 Orders per month (2000 Total)</li>
                <li>Smart AI Templates for Email</li>
                <li>Smart AI Templates for WhatsApp</li>
                <li>Manage Multiple Brands from Single Dashboard</li>
                <li>Integration With Shipping Providers</li>
                <li>Dedicated POC</li>
              </ul>
            </div>

            <div className={styles["plan-action-btn-container"]}>
              <button>
                Upgrade{" "}
                <span>
                  <Icon source={CircleTickMinor} />
                </span>
              </button>
            </div>
          </div>
        </Grid.Cell>
      </Grid>
    </>
  );
}
